import React, { Component, button } from "react";
import { Director, View } from "@millicast/sdk";

class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError:0, delay: 0};
    
  }
  componentDidMount(){
    this.loadStream();
  }
  playstream = () => {
     this.loadStream();
  }
  loadStream = async () => {
    try{
    const href = new URL(window.location.href);
    const streamName = href.searchParams.get("streamName");
    const streamAccountId = href.searchParams.get("streamAccountId");
    const delayValue = href.searchParams.get("delayValue") ? Number(href.searchParams.get("delayValue")) : 0;
    
    // Create audio context
    const audioContext = new window.AudioContext({ sampleRate: 48000 });
    let delayNode = audioContext.createDelay(30);
    delayNode.delayTime.value = delayValue;
    
    const tokenGenerator = () =>
      Director.getSubscriber({
        streamName: streamName, //'l2a8e5y6',
        streamAccountId: streamAccountId, //'4P2QPW'
      });

      // MillicastView object
    let millicastView = null;
    millicastView = new View(streamName, tokenGenerator, null, true);
    millicastView.on("track", ({ track }) => {
      // Ignore non audio tracks
      if (track.kind !== "audio") {
        return;
      }
      
      const stream = (window.stream = new MediaStream([track]));
      // Chrome needs a dummy audio element to start pumping audio in the webaudio media soruce
      const audio = document.createElement('audio')
      audio.srcObject = stream
      //audio.muted = true
      //audio.volume = 0;
      if(delayValue > 0)
      {
        audio.muted = true
      }
      audio.play()
 
      // Create media source
      var source = null;
      source = audioContext.createMediaStreamSource(stream);

      // Creat primary graph, connect webrtc with the delay node and play it in the default destination
      source.connect(delayNode).connect(audioContext.destination);
    });

    await millicastView.connect();
    this.setState({ hasError: 0})
  }
  catch(e){
    this.setState({hasError: 1})
    console.log("error", e.message)
  }
  };

  render() {
    return ( 
    this.state.hasError == 1 ? 
    <span className="alert alert-warning">No direct feed available</span> :
     this.state.hasError == 0 && 
     <div>
      <span>Direct feed</span>
      {/* <button onClick={ () => this.playstream()}>
      Click me!
    </button> */}
      </div>
     ); 
     
  }
}

export default Player;
